import React from 'react';

import { pdfjs } from 'react-pdf';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { getMergeColor, isMergeSolved } from './utils';
import { DocumentMerge } from '../../types/RealtaDocument';
import { documentService } from '../../services/DocumentService';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type MergeViewProps = {
  merges: DocumentMerge[];
  focusMerge?: DocumentMerge | null;
  showDetails?: boolean;
  onMergeClick?: (merge: DocumentMerge) => void;
  onSolve?: () => Promise<void>;
};

const MergeView: React.FC<MergeViewProps> = ({ merges, focusMerge, showDetails = true, onMergeClick, onSolve }) => {
  const [selectedMerge, setSelectedMerge] = React.useState<DocumentMerge | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSolveMerge = async (merge?: DocumentMerge) => {
    setIsLoading(true);
    try {
      if (!merge || !merge.src || !merge.tgt) {
        throw new Error('Merge is not defined');
      }
      await documentService.solveMerge(merge.src?.uuid, merge.tgt?.uuid);
      onSolve && (await onSolve());
    } catch (error) {
      console.error('Error solving merge', error);
    } finally {
      setSelectedMerge(undefined);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box p={1}>
        {merges
          .sort((a, b) => (a.src?.startBoundryY || 0) - (b.src?.startBoundryY || 0))
          .sort((a, b) => (a.src?.startBoundryPage || 0) - (b.src?.startBoundryPage || 0))
          .map((merge, index) => (
            <Box
              mb={2}
              key={index}
              borderRadius={2}
              bgcolor={getMergeColor(merge)}
              py={1}
              px={2}
              onClick={() => {
                if (onMergeClick) {
                  onMergeClick(merge);
                }
              }}
              sx={{
                cursor: 'pointer',
                border: merge.src?.uuid === focusMerge?.src?.uuid ? '1px solid black' : undefined,
              }}
            >
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  Path: {merge.idPath.join(' ')}
                </Typography>
              )}
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  idPathMatch: {merge.idPathMatch.toString()}
                </Typography>
              )}
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  sectionHashMatch: {merge.sectionHashMatch.toString()}
                </Typography>
              )}
              {showDetails && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  firstParHashMatch: {merge.firstParHashMatch.toString()}
                </Typography>
              )}
              {showDetails && merge.srcComments?.length && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  Source Comments: {JSON.stringify(merge.srcComments)}
                </Typography>
              )}
              {showDetails && merge.tgtComments?.length && (
                <Typography fontSize={12} fontWeight="bold" textTransform="capitalize">
                  Target Comments: {JSON.stringify(merge.tgtComments)}
                </Typography>
              )}
              {!isMergeSolved(merge) && (
                <Box display="flex" justifyContent="right" mt={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={() => setSelectedMerge(merge)}
                    disabled={isLoading}
                  >
                    Solve
                  </Button>
                </Box>
              )}
            </Box>
          ))}
      </Box>
      <Dialog open={!!selectedMerge} onClose={() => setSelectedMerge(undefined)}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Solve Merge
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to solve this merge?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setSelectedMerge(undefined)} disabled={isLoading}>
            Cancel
          </Button>
          <Button autoFocus onClick={() => onSolveMerge(selectedMerge)} color="secondary" disabled={isLoading}>
            Solve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MergeView;
