import { DocumentDiffChangeType } from '../../types/RealtaDocument';

export type TextItemSpecs = {
  text: string;
  x: number;
  y: number;
  width: number;
  height: number;
  page: number;
};

export type PositionSpecs = {
  x: number;
  y: number;
  width: number;
  height: number;
  page: number;
};

export type DiffSpecs = {
  type: DocumentDiffChangeType;
  text: string[];
  positions: PositionSpecs[];
};

// A4 size
export const WIDTH = 630;
export const HEIGHT = 891;
