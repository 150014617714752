import React, { forwardRef, useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import { Box } from '@mui/material';
import { getMergeColor, inchToPx } from './utils';
import { DocumentMerge } from '../../types/RealtaDocument';
import { HEIGHT, WIDTH } from './type';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type DocumentViewProps = {
  url: string;
  mergeMap?: Map<string, DocumentMerge> | null;
  isRoot?: boolean;
  focusMergeChange?: DocumentMerge | null;
  onMergeChangeClick?: (merge: DocumentMerge, isRoot?: boolean) => void;
};

const DocumentView = forwardRef<HTMLDivElement, DocumentViewProps>(
  ({ url, mergeMap, focusMergeChange, onMergeChangeClick, isRoot = true }, ref) => {
    const [numPages, setNumPages] = useState<number>();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }

    const calculateHeight = (merge: DocumentMerge) => {
      if (
        !merge.src?.endBoundryPage ||
        !merge.src?.startBoundryPage ||
        !merge.src?.endBoundryY ||
        !merge.src?.startBoundryY
      ) {
        return { top: 0, height: 0 };
      }
      const { endBoundryPage, endBoundryY, startBoundryPage, startBoundryY } = merge.src;

      const calculatedStartBoundryPage = startBoundryPage > 1 ? startBoundryPage - 1 : 0;
      const top = HEIGHT * calculatedStartBoundryPage + inchToPx(startBoundryY);

      const offset = endBoundryPage - startBoundryPage === 0 ? 0 : HEIGHT * (endBoundryPage - startBoundryPage);
      const height = offset + HEIGHT * calculatedStartBoundryPage + inchToPx(endBoundryY) - top;
      return { top, height };
    };

    const handleShowChange = (e: React.MouseEvent<HTMLDivElement>, merge: DocumentMerge) => {
      onMergeChangeClick && onMergeChangeClick(merge, isRoot);
      const popup = document.createElement('div');
      popup.innerText = JSON.stringify(merge.tgt, null, 2);
      popup.style.position = 'absolute';
      popup.style.top = `${e.clientY}px`;
      popup.style.left = `${e.clientX + 10}px`;
      popup.style.backgroundColor = 'white';
      popup.style.padding = '5px';
      popup.style.border = '1px solid black';
      popup.style.zIndex = '1001';
      document.body.appendChild(popup);

      setTimeout(() => {
        document.body.removeChild(popup);
      }, 3000);
    };

    return (
      <Box position="relative" height="fit-content" sx={{ scrollBehavior: 'smooth' }} ref={ref}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {numPages &&
            Array.from({ length: numPages }, (_, index) => index + 1).map((pageNumber) => (
              /* This box to keep pdf pages are always in the same size */
              <Box width={WIDTH} height={HEIGHT} overflow="hidden">
                <Page pageNumber={pageNumber} width={WIDTH} />
              </Box>
            ))}
        </Document>
        {mergeMap?.values() &&
          Array.from(mergeMap.values()).map((merge, index) => (
            <Box
              key={index}
              position="absolute"
              top={calculateHeight(merge).top}
              height={calculateHeight(merge).height}
              left={0}
              width="100%"
              bgcolor={getMergeColor(merge)}
              zIndex={2}
              onClick={(e) => handleShowChange(e, merge)}
              sx={{
                cursor: 'pointer',
                border: merge.src?.uuid === focusMergeChange?.src?.uuid ? '1px solid black' : undefined,
                // pointerEvents: 'none',
              }}
            />
          ))}
      </Box>
    );
  }
);
export default DocumentView;
