import { DocumentMerge } from '../../types/RealtaDocument';
import { HEIGHT, WIDTH } from './type';

// merge.mergeCandidate?.isSolved means that the merge is manually solved
export const isMergeSolved = (merge: DocumentMerge) =>
  (merge.idPathMatch && merge.sectionHashMatch && merge.firstParHashMatch) || merge.mergeCandidate?.isSolved;

export const getMergeColor = (merge: DocumentMerge) => {
  if (isMergeSolved(merge)) {
    return 'rgba(0, 255, 0, 0.3)';
  }
  if (!merge.idPathMatch && !merge.sectionHashMatch && !merge.firstParHashMatch) {
    return 'rgba(255, 0, 0, 0.3)';
  }
  return 'rgba(255, 255, 0, 0.3)';
};

export function inchToPx(inches: number): number {
  // Physical dimensions of A4 paper in inches
  const physicalWidthInInches = 8.27;
  const physicalHeightInInches = 11.69;

  // Calculate PPI (Pixels Per Inch)
  const ppiWidth = WIDTH / physicalWidthInInches;
  const ppiHeight = HEIGHT / physicalHeightInInches;

  // Average PPI for more uniform scaling
  const averagePPI = (ppiWidth + ppiHeight) / 2;

  // Convert inches to pixels
  return Math.round(inches * averagePPI);
}
