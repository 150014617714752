// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import MergeDocument from '../components/MergeDocument/MergeDocument';

const Compare = () => {
  return (
    <AppLayout>
      <Router>
        <MergeDocument path="/document-merge" />
      </Router>
    </AppLayout>
  );
};
export default Compare;
