import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Popper, Switch, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { documentService } from '../../services/DocumentService';
import { DocumentDiffChangeMap, DocumentNode } from '../../types/RealtaDocument';
import Close from '@mui/icons-material/Close';
import TOCItem from '../DocumentPage/TOCItem';
import { CommentCount } from '../../types/Comment';

const TOC: React.FC<{
  documentUuid: string;
  diffChanges?: DocumentDiffChangeMap;
  onClick: (node: DocumentNode) => void;
}> = ({ documentUuid, diffChanges, onClick }) => {
  const [loadComment, setLoadComment] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [headings, setHeadings] = useState<DocumentNode[]>([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const init = async () => {
      const headings = await documentService.getDocumentHeadingSections({ documentUuid });
      setHeadings(headings);
    };

    init();
  }, [documentUuid]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [commentCounts, setCommentCounts] = useState<CommentCount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      if (loadComment) {
        setIsLoading(true);
        const commentCounts = await documentService.getNodeSubSectionComments({ uuid: documentUuid, isDocument: true });
        setCommentCounts(commentCounts);
        setIsLoading(false);
      }
    };

    init();
  }, [documentUuid, loadComment]);

  return (
    <Box>
      <Button onClick={handleClick} sx={{ color: '#000', p: 0, minWidth: 48 }} disableRipple>
        <MenuIcon />
      </Button>
      <Popper style={{ zIndex: 1002, maxWidth: '30w', width: '30vw' }} anchorEl={anchorEl} open={open}>
        <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '12px !important',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              <Switch
                size="small"
                checked={loadComment}
                onChange={() => setLoadComment(!loadComment)}
                color="secondary"
              />
              <Typography fontSize={10} whiteSpace="nowrap">
                Show Comments
              </Typography>
            </Box>
            <Button
              color="inherit"
              disableRipple
              variant="contained"
              onClick={handleClose}
              sx={{ mb: 1, ml: 'auto', mr: 0.5, display: 'flex' }}
            >
              <Close color="inherit" />
            </Button>
          </Box>
          {isLoading && <LinearProgress color="secondary" />}
          {headings.map((section) => (
            <TOCItem
              key={section.uuid}
              node={section}
              documentId={documentUuid}
              onSelect={onClick}
              loadComment={loadComment}
              commentCount={commentCounts.find((c) => c.id === section.uuid)}
              diffChanges={diffChanges}
            />
          ))}
        </Box>
      </Popper>
    </Box>
  );
};
export default TOC;
